<template>
	<div id="login">
		<v-head></v-head>
		<div class="tabbar">忘記密碼</div>
		<div class="form">
			<div class="form-item">
				<img src="../../assets/imgs/mobile/icon_dianhua@2x.png" class="icon icon-phone">
				<input type="tel" placeholder="請輸入手機號碼" v-model="phone" @input="onPhoneInput">
			</div>
			<div class="form-item">
				<img src="../../assets/imgs/mobile/icon_yanzhenma@2x.png" class="icon icon-phonecode">
				<input type="number" placeholder="輸入短信驗證碼" v-model="phonecode">
                <div :class="{'get-phonecode': true, 'active': phonecodeVisible}" @click="getPhoneCode">{{phoneCodeText}}</div>
			</div>
			<div class="form-item">
				<img src="../../assets/imgs/mobile/icon_mima@2x.png" class="icon icon-password">
				<input type="password" placeholder="輸入新的賬號密碼" v-model="password">
			</div>
			<div class="form-item">
				<img src="../../assets/imgs/mobile/icon_mima@2x.png" class="icon icon-password">
				<input type="password" placeholder="確認賬號密碼" v-model="confirmPassword">
			</div>
			<div class="btn-confirm" @click="onSubmit"><div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在操作...' : '找回密碼'}}</div>
		</div>
	</div>
</template>

<script>
	
	import vHead from '@/layouts/MobileHead.vue'
	
	export default {
		data() {
			return {
				loading: false,
                isLogin: true,
				signedPrivacy: false,
				phonecodeVisible: false,
				phoneCodeText: '獲取驗證碼',
				timeCount: -1,
				codeKey: '',
				phone: '',
				phonecode: '',
				password: '',
				confirmPassword: '',
			}
		},
		components: {
			vHead
		},
		mounted: function() {
			if(this.$route.path == '/register') {
				this.isLogin = false
			}
        },
        methods: {
            onCheckboxChange: function(){
                this.signedPrivacy = !this.signedPrivacy
			},
			onPhoneInput: function(){
				if(/^1[3-9]{1}[0-9]{9}$/.test(this.phone.trim()) || /^([5|6|8|9])\d{7}$/.test(this.phone.trim())) {
					this.phonecodeVisible = true
				} else {
					this.phonecodeVisible = false
				}
			},
			getPhoneCode: function(){
				if(this.timeCount != -1) return
				if(! (/^1[3-9]{1}[0-9]{9}$/.test(this.phone.trim()) || /^([5|6|8|9])\d{7}$/.test(this.phone.trim()))) {
					this._toast.warning(this, '請輸入手機號')
					return
				}
				
				this.$store.dispatch('getVCode',{codeType: 2, phoneNumber: this.phone})
				.then((rs) => {
					this.codeKey = rs.codeKey
					this._toast.success(this, '驗證碼已經發送')
					
					this.timeCount = 90
					this.phoneCodeText = `${this.timeCount}秒後可重發`

					this.timer && clearInterval(this.timer)
					this.timer = setInterval(() => {
						this.timeCount --
						if(this.timeCount < 0) {
							this.phoneCodeText = '獲取驗證碼'
							this.timer && clearInterval(this.timer)
						} else {
							this.phoneCodeText = `${this.timeCount}秒後可重發`
						}
					}, 1000);
				}).catch((msg) => {
					this.timeCount = -1
					this._toast.warning(this, msg || '操作失敗，請稍候重試')
				})
			},
			onSubmit: function(){
				if(this.loading) return

				if(! this.phone.trim()){
					this._toast.warning(this, '請輸入手機號')
					return
				}
				if(! /^1[3-9]{1}[0-9]{9}$/.test(this.phone.trim()) && ! /^([5|6|8|9])\d{7}$/.test(this.phone.trim())) {
					this._toast.warning(this, '手機號格式不正確')
					return
				}
				if(!this.phonecode) {
					this._toast.warning(this, '請輸入短信驗證碼')
					return
				}
				if(parseInt(this.phonecode) != this.phonecode || this.phonecode.length != 6) {
					this._toast.warning(this, '驗證碼輸入不正確')
					return
				}
				if(! this.password) {
					this._toast.warning(this, '請輸入新的賬號密碼')
					return
				}
				if(this.password.length < 6) {
					this._toast.warning(this, '密碼長度不能小於6位')
					return
				}
				if(! this.confirmPassword) {
					this._toast.warning(this, '請再次輸入賬號密碼')
					return
				}
				if(this.confirmPassword != this.password) {
					this._toast.warning(this, '兩次密碼輸入不一致')
					return
				}
				
				this.loading = true
                var params = {
                    codeKey: this.codeKey,
                    phone: this.phone,
                    numcode: this.phonecode,
                    newPwd: this.confirmPassword,
                }

                this.$store.dispatch('resetPassword', params)
                .then(() => {
					this.$$message
                    this._toast.success(this, '密碼已重置，請重新登錄')
                    setTimeout(() => {
                        this.loading = false
                        this.$router.push('/login')
                    }, 500);
                }).catch((error) => {
                    this._toast.success(this, error || '操作失敗')
                    this.loading = false
                })
				
			}
        }
	}
</script>

<style lang="scss" scoped>
	#login {
		padding-top: 83px;
		width: 100%;
		height: 100%;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.tabbar {
		display: block;
		height: 34px;
		line-height: 34px;
		columns: #231F20;
		font-size: 36px;
		font-weight: bold;
		margin-top: 91px;
		margin-bottom: 80px;
		position: relative;
	}
	
	.form {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.form > .form-item {
		display: flex;
		flex-direction: row;
		height: 88px;
		width: 540px;
		border-bottom: 1px solid #E8E8E8;
		align-items: center;
		font-weight: bold;
		color: #231F20;
		font-size: 28px;
        position: relative;
	}

	.form > .form-item:hover {
		border-color: #7BD5DF;
	}
	
	.form > .form-item > input::placeholder {
		font-size: 24px;
		font-weight: 400;
		color: #969696;
	}

	.form > .form-item > .icon {
		width: 16px;
		height: 16px;
		margin: 0px 18px 0px 12px;
	}

    .form > .form-item > .get-phonecode {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 160px;
        height: 48px;
        border-radius: 24px;
        border: 1px solid #969696;
        text-align: center;
        line-height: 48px;
        font-size: 24px;
        font-weight: 400;
        color: #969696;
    }

    .form > .form-item > .get-phonecode.active {
        color: #FF8000;
        border-color: #FF8000;
    }

	.form > .btn-confirm {
		box-shadow: 0px 10px 25px 0px #9EE3E8;
		width: 80%;
		height: 80px;
		color: #fff;
		font-size: 34px;
		font-weight: bold;
		border-radius: 40px;
		background-color: #36C4D0;
		margin-top: 150px;
		user-select: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.form > .btn-confirm:active {
		box-shadow: 0px 5px 15px 0px #9EE3E8;
	}

	.form > .forget-password {
		margin-top: 142px;
		font-weight: 400;
		font-size: 28px;
		color: #636363;
		line-height: 28px;
		text-decoration: underline;
	}

    .form > .agree-protocol {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: 25px;
        width: 100%;
        align-items: center;
        margin-top: 91px;
        font-size: 24px;
        color: #969696;
        font-weight: 400;
    }
    
    .form > .agree-protocol > .my-checkbox {
        width: 24px;
        height: 24px;
        border: 1px solid #969696;
        margin-right: 8px;
        box-sizing: border-box;
    }

    .form > .agree-protocol.checked > .my-checkbox {
        position: relative;
    }

    .form > .agree-protocol.checked > .my-checkbox::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 26.7px;
        width: 21.9px;
        background-image: url(../../assets/imgs/mobile/img_goux@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .form > .agree-protocol a {
        color: #231F20;
        text-decoration: underline;
    }

    /** loading start */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }
    
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 42px;
        height: 42px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        margin-top: 14px;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */
</style>
